import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleText from "../components/text/title-text/title-text"

function ServicesPage() {
  return (
    <Layout>
      <SEO title="Servicios" />
      <TitleText text="Calidad y experiencia al servicio de sus mascotas" />
    </Layout>
  )
}

export default ServicesPage
